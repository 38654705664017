<template>
	<center>
		<h5>Bạn đã đặt hàng thành công. Mã đơn hàng của bạn: {{ this.$route.params.id }}</h5>
		<div class="py-2">
			<CButton type="button" class="btn btn-success" @click="onGotoPament">Đến trang thanh toán</CButton>
			<CButton type="button" class="btn btn-success" @click="onPamentLater" style="margin-left:5px">Thanh toán sau</CButton>
		</div>		
	</center>
	
</template>

<script>
    
	export default {
        name: 'NoticeDealerOrder',
		data() {
			return {				
			}
		},
        computed: {           
		},
		components: {			
        },
		methods: {           
            onGotoPament() {
                this.$router.push('/warehouses/Approve/' + this.$route.params.id);
			},
            onPamentLater() {
                this.$router.push('/warehouses/receivelist');
            },
		},		
	}
</script>